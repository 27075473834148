import { RewardsExtension } from '/~/core/extension'
import { useOtp } from '/~/extensions/otp/composables/use-otp'
import { useVerificationRoutes } from '/~/composables/access/use-verification-routes'

class OtpExtension extends RewardsExtension {
  async setEwalletRoutes() {
    const { isEnrolmentOtpEnabled } = useOtp()

    if (!isEnrolmentOtpEnabled.value) {
      return []
    }

    const { setEwalletVerificationRoutes } = useVerificationRoutes()

    return setEwalletVerificationRoutes()
  }
}

export default OtpExtension
